import { jsonToArray } from '@helpers/contentful'
import { getLink } from '@helpers/env'
import { analytics } from '@helpers/analytics'
import { File } from 'types/contentful/restApi'
import styles from './styles.module.scss'
import ButtonMinimal from '@components/ui/Button/ButtonMinimal'

type ShoppableCardProps = Card & {
  index: number
  firstTimeUser: boolean
}

const ShoppableCard = ({
  title,
  button,
  image,
  index,
  firstTimeUser,
  copyColor = 'light'
}: ShoppableCardProps) => {
  const handleClick = () => {
    analytics('Click', {
      ...button.event,
      label: 'Shoppable',
      type: firstTimeUser ? 'New' : 'Existing',
      action: `Module ${index + 1}`
    })
  }
  return (
    <a href={getLink(button.uri)} onClick={handleClick} className={`${styles.shoppableCard} ${styles[`theme_${copyColor}`]}`}>
      <div className={styles.shoppableCard__image}>
        <div className={styles.shoppableCard__imageMedia}>
          <img src={`https:${image.file.url}`} alt={title} />
        </div>
        <h3 className={styles.shoppableCard__title}>{title}</h3>
        <ButtonMinimal
          classes={styles.shoppableCard__imageButton}
          variant='light'
          underlined={true}
          onClick={() => {
          window.location.href = button.uri
        }}>{button.text}</ButtonMinimal>
      </div>
      <div className={styles.shoppableCard__wrapper}></div>
    </a>
  )
}

type CardButton = {
  uri: string
  text: string
  event: {
    label?: string
    category?: string
  }
}

type CardImage = {
  title: string
  file: File
}

type Card = {
  title: string
  button: CardButton
  image: CardImage
  copyColor: 'light' | 'dark'
}

type ShoppableCardsProps = {
  cards: Record<string, Card>
  firstTimeUser: boolean
}

const ShoppableCards = ({ cards, firstTimeUser }: ShoppableCardsProps) => {
  const cardElements = jsonToArray(cards) as Card[]

  return (
    <section className={styles.shoppableCards}>
      <div className={styles.shoppableCards__Wrapper}>
      {cardElements.map(({ title, button, image, copyColor }, index) => (
        <ShoppableCard
          key={title}
          title={title}
          button={button}
          image={image}
          index={index}
          firstTimeUser={firstTimeUser}
          copyColor={copyColor}
        />
      ))}
      </div>
    </section>
  )
}

export default ShoppableCards
